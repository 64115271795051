<style>
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .count-btn {
    border-radius: 50px;
    padding: 5px 15px;
    font-weight: bold;
    margin-left: 10px;
  }
</style>
<template>
  <div>
    <!-- count section  -->
    <div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Total :</span> <span> {{ total_customer }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Leads :</span> <span> {{ total_leads }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Applied :</span> <span> {{ applied }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Approved :</span> <span> {{ approved }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Sanctioned :</span> <span> {{ sanctioned }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Disbursed :</span> <span> {{ disbursed }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Completed :</span> <span> {{ completed }}</span>
      </div>
      <div class="d-inline-block elevation-1 count-btn">
        <span> Rejected :</span> <span> {{ rejected }}</span>
      </div>
    </div>

    <v-row class="mt-3 mx-0">
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.display_daterange"
              v-on="on"
              class="filter"
              dense
              filled
              label="Filter by date Range"
              outlined
              placeholder="Filter by date Range"
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.date_range.length > 1"
                  @click="clearDateFilter()"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.date_range" :max="today" range>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                filters.date_range = [];
                date_menu = false;
              "
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-select
          v-model="filters.loan_steps"
          :items="loan_steps"
          clearable
          item-text="name"
          item-value="value"
          dense
          filled
          hide-details
          label="Select Loan Status"
          menu-props="auto"
          outlined
          placeholder="Select Loan Status"
          single-line
          @change="getCustomerList"
        >
        </v-select>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-select
          v-model="filters.profile_status"
          :items="profile_status_list"
          clearable
          dense
          filled
          hide-details
          label="Select Profile status"
          menu-props="auto"
          outlined
          placeholder="Select Loan Status"
          single-line
          @change="getCustomerList"
        ></v-select>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <v-combobox
          v-model="filters.current_city"
          :items="city_list"
          :loading="city_loading"
          :search-input.sync="city_search"
          clearable
          dense
          filled
          hide-details
          label="Select city"
          outlined
          placeholder="Select city"
          single-line
          @change="getCustomerList"
          @focus="getCitylist"
          @keyup="getCitylist"
        >
          <template v-slot:append-item>
            <div v-intersect="endIntersect"></div>
          </template>
        </v-combobox>
      </v-col>
      <v-col class="text-center" cols="12" md="3">
        <v-menu
          v-model="fd_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.fd_display_date"
              v-on="on"
              :label="$lang.FOLLOW_UP_DATE"
              :placeholder="$lang.FOLLOW_UP_DATE"
              class="filter"
              dense
              filled
              outlined
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.follow_up_date"
                  @click="
                    filters.follow_up_date = null;
                    filters.fd_display_date = null;
                    clearDateFilter();
                  "
                  >mdi-close
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.follow_up_date">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="date_menu = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="text-center" cols="12" md="3">
        <v-select
          v-model="filters.source"
          :items="source_list"
          clearable
          dense
          filled
          hide-details
          label="Select lead source"
          menu-props="auto"
          outlined
          placeholder="Select lead source"
          single-line
          @change="getCustomerList"
        ></v-select>
      </v-col>

      <!-- for status date filter       -->
      <v-col class="text-center" cols="12" md="3">
        <v-menu
         v-if="this.isLoanStatusLead == false"
          v-model="status_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.display_status_daterange"
              v-on="on"
              class="filter"
              dense
              filled
              label="Filter by Status date"
              outlined
              placeholder="Filter by Status date"
              readonly
              single-line
            >
              <template slot="append">
                <v-icon
                  v-if="filters.status_date_range.length > 1"
                  @click="clearStatusDateFilter()"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.status_date_range" :max="today" range>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                filters.status_date_range = [];
                status_date_menu = false;
              "
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="text-center" cols="12" md="3">
        <div class="text-right">
          <v-btn
            v-if="
              $store.state.permission_list.includes($keys.EXPORT_CUSTOMER_CSV)
            "
            color="primary"
            outlined
            @click="downloadCsv"
          >
            <v-icon class="mr-2" small>mdi-download</v-icon>
            {{ $lang.EXPORT_CSV }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card flat outlined>
      <v-data-table
        :disable-sort="true"
        :headers="headers"
        :items="itemsWithSno"
        :items-per-page="$keys.PAGE_LENGTH"
        :loading="table_loading"
        class="border-a border-0 custome-header-bg"
        hide-default-footer
      >
        <template v-slot:item.sno="{ item }">
          <p>{{ item.sno + (filters.page_number - 1) * $keys.PAGE_LENGTH }}</p>
        </template>

        <template v-slot:item.name="{ item }">
          <p class="pt-2">{{ item.name }}</p>
          <p class="pt-2">{{ item.mobile }}</p>
          <p class="pb-2">{{ item.email }}</p>
        </template>

        <template v-slot:item.referred_by="{ item }">
          <div v-if="item.referred_by">
            <p class="pt-2">{{ item.referred_by.name }}</p>
            <p class="pt-2">{{ item.referred_by.mobile }}</p>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-inline-block">
            <div>
              <v-btn
                color="secondary_2"
                fab
                small
                text
                @click="addComment(item)"
              >
                <v-icon>mdi-message-text-outline</v-icon>
              </v-btn>
            </div>
            <div v-if="item.unblock_kyc" class="tooltip">
              <v-btn
                color="secondary_2"
                fab
                small
                text
                @click="unLockKyc(item.customer_id)"
              >
                <v-icon>mdi-lock-open-variant-outline</v-icon>
              </v-btn>
              <span class="tooltiptext">Unlock the KYC attempts</span>
            </div>
          </div>
          <div
            :class="item.unblock_kyc ? 'pt-5' : ''"
            class="d-inline-block"
            style="vertical-align: top"
          >
            <v-btn
              :disabled="item.customer_id == '-' ? true : false"
              fab
              small
              text
              @click="gotoCustomerProfile(item)"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:item.loan_step="{ item }">
          <p v-if="item.loan_step == 'step_in_person_verification'">
            In video verification
          </p>
          <p v-else>
            {{
              item.loan_step
                .replace("step_", "")
                .charAt(0)
                .toUpperCase() +
                "" +
                item.loan_step
                  .replace("step_", "")
                  .replace(/_/g, " ")
                  .slice(1)
                  .toLowerCase()
            }}
          </p>
          <small v-if="item.action_date" class="pb-2">
            {{ item.action_date }}
          </small>
        </template>

        <template v-slot:item.follow_up_date="{ item }">
          <p v-if="item.follow_up_date">
            {{ new Date(item.follow_up_date).toDateString().slice(4) }}
          </p>
          <p v-else>-</p>
        </template>
      </v-data-table>
    </v-card>

    <v-pagination
      v-model="filters.page_number"
      :length="total_page_count"
      :total-visible="7"
      class="custome-pagination"
      @input="getCustomerList"
    ></v-pagination>

    <AddComments
      ref="comment_dialog"
      :dialog="comment_dialog"
      @update="getCustomerList"
    />
  </div>
</template>
<script>
  import { mapGetters } from "vuex";

  export default {
    components: {
      AddComments: () => import("@/components/customer/dialog/AddComments"),
    },
    data() {
      return {
        table_loading: false,
        page_number: 1,
        total_page_count: 2,
        customer_list: [],
        total_customer: 0,
        total_leads: 0,
        applied: 0,
        approved: 0,
        sanctioned: 0,
        disbursed: 0,
        rejected: 0,
        completed: 0,
        source_list: [
          "Facebook Ads",
          "Google Ads",
          "Newspaper",
          "Friends & Relatives",
          "Hoardings",
          "Google Search",
          "Others",
        ],
        loan_status_list: [
          "Lead",
          "Applied",
          "Approved",
          "Sanctioned",
          "Disbursed",
          "Rejected",
          "Completed",
        ],
        profile_status_list: [
          "Signed in",
          "Basic info",
          "Address Details",
          "Reference Details",
          "Employer Details",
          "Bank Details",
          "Document Upload",
          "Profile Completed",
        ],
        headers: [
          { text: "SN", value: "sno" },
          {
            text: "CIF ID ",
            align: "start",
            filterable: false,
            value: "lms_customer_id",
          },
          { text: "Customer info", value: "name" },
          // {text: 'Mobile No.', value: 'mobile'},
          { text: "City", value: "current_city" },
          { text: "Type", value: "loan_step", width: "115px" },
          { text: "Profile Status", value: "user_profile_status" },
          { text: "Signup Date", value: "created" },
          { text: "Referred by", value: "referred_by" },
          { text: "Source", value: "where_did_you_know", align: "center" },
          { text: "Follow up date", value: "follow_up_date", align: "center" },
          { text: "Comment", value: "comment" },
          { text: "", value: "action", width: "115px" },
        ],
        loan_steps: [
          { name: "Lead", value: "Lead" },
          { name: "Applied", value: "Applied" },
          { name: "Document Verified", value: "Document Verified" },
          { name: "Approved", value: "Approved" },
          { name: "Sanctioned", value: "Sanctioned" },
          { name: "In Video Verification", value: "In Video Verification" },
          { name: "Setup Auto Debit", value: "Setup Auto Debit" },
          { name: "Money on the way", value: "Money on the way" },
          { name: "Disbursed", value: "Disbursed" },
          { name: "Repayment", value: "Repayment" },
          { name: "Completed", value: "Completed" },
          { name: "Rejected", value: "Rejected" },
        ],
        date_menu: false,
        fd_date_menu: false,
        display_daterange: null,
        today: new Date().toISOString().substr(0, 10),
        city_list: [],
        city_search_query: "",
        city_loading: false,
        city_page: 1,
        city_search: "",
        comment_dialog: {
          flag: false,
          comment: null,
          follow_up_date: null,
          label: null,
          id: null,
        },

        // for status date filter
        status_date_menu: false,
        isLoanStatusLead: true,


      };
    },
    computed: {
      ...mapGetters({
        search_query: "getSearchText",
        filters: "customer/getFilters",
      }),
      itemsWithSno() {
        return this.customer_list.map((d, index) => ({ ...d, sno: index + 1 }));
      },
    },
    watch: {
      search_query(new_search_query, old_search_query) {
        this.getCustomerList(true);
        // console.log(new_search_query, old_search_query, "ABORT");
        // console.log(new_search_query.includes(old_search_query));
        // if (!old_search_query) {
        //   this.getCustomerList();
        // } else if (
        //   new_search_query != old_search_query &&
        //   !new_search_query.includes(old_search_query)
        // ) {
        //   console.log("CALLED");
        //   this.getCustomerList(true);
        // }
      },
    },

    created() {
      console.log(this.$route.meta.show_back_btn);
      this.getCustomerList(true);
    },

    methods: {
      /* fetching the customer list form server*/
      getCustomerList(isCancel = false) {
        const self = this;
        self.table_loading = true;
        self.toggle_loading();

        // for status date filter
        let step_status =  this.filters.loan_steps
            ? "step_" + this.filters.loan_steps.replace(/ /g, "_").toLowerCase()
            : null;

            // console.log("step",step_status)
        
        if(step_status == null || step_status == "step_lead"){
          this.isLoanStatusLead = true
          this.daterange = [];
          this.filters.display_status_daterange = null;
          this.filters.status_date_range = []
        }
        if(step_status != "step_lead" && step_status != null){
          this.isLoanStatusLead = false;

        }

        let params = {
          page_number: this.filters.page_number,
          page_length: this.$keys.PAGE_LENGTH,
          search_query: this.search_query,
          loan_status: this.filters.loan_status,
          loan_step: this.filters.loan_steps
            ? "step_" + this.filters.loan_steps.replace(/ /g, "_").toLowerCase()
            : null,
          user_profile_status: this.filters.profile_status,
          from_date: this.filters.date_range[0],
          to_date: this.filters.date_range[1],
          // for status date filter
          created_from: this.filters.status_date_range[0],
          created_till: this.filters.status_date_range[1],

          city: this.filters.current_city,
          follow_up_date: this.filters.follow_up_date,
          where_did_you_know: this.filters.source,
          label: this.filters.label,
        };

        console.log(params);
        const successHandler = (response) => {
          self.customer_list = [];
          self.table_loading = false;
          if (response.data.success) {
            self.customer_list = response.data.customer_list;
            self.total_page_count = response.data.total_page_count;
            self.total_customer = response.data.total_customer;
            self.total_leads = response.data.Lead;
            self.applied = response.data.applied_loan;
            self.approved = response.data.Approved;
            self.sanctioned = response.data.Sanctioned;
            self.disbursed = response.data.Disbursed;
            self.completed = response.data.Completed;
            self.rejected = response.data.Rejected;
          }
        };
        const finallyHandler = () => {
          // self.table_loading = false;
        };

        self.request_GET(
          self,
          self.$urls.CUSTOMER_LIST,
          params,
          successHandler,
          null,
          null,
          finallyHandler,
          isCancel
        );
      },
      /* fetching the cit list form server*/
      getCitylist(append_city = false) {
        const self = this;
        self.city_loading = true;
        let params = {
          search_query: this.city_search,
          page_number: this.city_page,
        };
        console.log(params);
        const successHandler = (response) => {
          if (response.data.success) {
            if (append_city)
              self.city_list = self.city_list.concat(response.data.city_list);
            else self.city_list = response.data.city_list;
          }
        };
        const finallyHandler = () => {
          self.city_loading = false;
        };

        self.request_GET(
          self,
          self.$urls.CITY_LIST,
          params,
          successHandler,
          null,
          null,
          finallyHandler
        );
      },
      endIntersect(entries, observer, isIntersecting) {
        if (isIntersecting) {
          this.city_page++;
          this.getCitylist(true);
        }
      },
      gotoCustomerProfile(customer) {
        // this.$router.push({
        //   name: "customer-details",
        //   params: {
        //     id: this.encript(customer.customer_id),
        //   },
        // });
        
      // redirect to new customer details page
      this.$router.push({
        name: "customer-details",
        params: {
          id: this.encript(customer.customer_id),
        },
      });
      },
      formateDisplayDate() {
        if (this.filters.follow_up_date)
          this.filters.fd_display_date = this.display_date_formate(
            this.filters.follow_up_date
          );
        if (this.filters.date_range.length > 1)
          this.filters.display_daterange = this.formateDisplayDateRange(
            this.filters.date_range
          );
        if (this.filters.status_date_range.length > 1)
          this.filters.display_status_daterange = this.formateDisplayDateRange(
            this.filters.status_date_range
          );
        this.date_menu = false;
        this.status_date_menu = false;
        this.fd_date_menu = false;
        this.getCustomerList();
      },
      clearDateFilter() {
        this.daterange = [];
        this.filters.display_daterange = null;
        this.filters.date_range = [];
        this.getCustomerList();
      },
      clearStatusDateFilter() {
        this.daterange = [];
        this.filters.display_status_daterange = null;
        this.filters.status_date_range = [];
        this.getCustomerList();
      },
      formateDisplayDateRange(daterange) {
        console.log(daterange);
        let date1 = daterange[0];
        let date2 = daterange[1];
        let to = "";
        let from = "";
        if (date1 > date2) {
          to = date1;
          from = date2;
        } else {
          to = date2;
          from = date1;
        }
        console.log(from);
        if (!to) to = from;
        daterange[0] = from;
        daterange[1] = to;
        from = this.display_date_formate(from);
        to = this.display_date_formate(to);
        return `${from} to ${to}`;
      },
      addComment(item) {
        this.comment_dialog.comment = item.comment == " - " ? "" : item.comment;
        this.comment_dialog.id = item.customer_id;
        this.comment_dialog.follow_up_date = item.follow_up_date;
        this.comment_dialog.label = item.label;
        this.comment_dialog.flag = true;
        this.$refs.comment_dialog.formateDisplayDate();
      },
      match(arr) {
        arr.find((element) => {
          if (element.includes("Video Verification")) {
            return true;
          }
        });
      },
      /* Download CSV*/

      downloadCsv() {
        console.log("methods_18");
        let loan_step = this.filters.loan_steps
          ? "step_" + this.filters.loan_steps.replace(/ /g, "_").toLowerCase()
          : null;
        if (loan_step == "step_in_video_verification") {
          loan_step = "step_in_person_verification";
        }
        let url = this.$urls.BASE_URL + this.$urls.CUSTOMER_LIST_CSV + "?";
        if (this.filters.loan_status)
          url += "?loan_status=" + this.filters.loan_status;
        if (loan_step) url += "&loan_step=" + loan_step;
        if (this.filters.profile_status)
          url += "&user_profile_status=" + this.filters.profile_status;
        if (this.filters.date_range[0])
          url += "&from_date=" + this.filters.date_range[0];
        if (this.filters.date_range[1])
          url += "&to_date=" + this.filters.date_range[1];
        if (this.filters.current_city)
          url += "&city=" + this.filters.current_city;

        window.open(url, "_blank");
      },
      /* unloack the kYC CSV*/
      unLockKyc(customer_id) {
        const self = this;
        var form = new FormData();
        form.append("user_id", customer_id);
        const successHandler = (response) => {
          if (response.data.success) {
            self.showSnakeBar("success", "KYC Attempt Unlocked");
            self.getCustomerList();
          }
        };
        const finallyHandler = () => {
          self.login_loading = false;
        };
        this.request_POST(
          self,
          this.$urls.UNBLOCK_KYC,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      },
    },
  };
</script>
