<style scoped>
.customer-profile-card-wrapper {
  position: relative;
}
.customer-profile-card {
  position: sticky;
  top: 10px;
}
.customer-profile-card-wrapper {
  position: relative;
}
.customer-profile-card {
  position: sticky;
  top: 10px;
}
</style>

<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          class="customer-profile-card-wrapper"
          cols="3"
          md="12"
          lg="3"
          sm="12"
        >
          <CustomerProfileCard
            class="customer-profile-card"
            :data="this.view_loan_details"
            @toggleDetails="viewDetails()"
          />
        </v-col>
        <v-col class="customer-details">
          <div v-if="this.view_loan_details">
            <CustomerLoanDetails />
          </div>
          <div v-else>
            <CustomerBasicDetails />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    CustomerProfileCard: () =>
      import("@/components/new-customer/CustomerProfileCard"),
    CustomerBasicDetails: () =>
      import("@/components/new-customer/CustomerBasicDetails"),
    CustomerLoanDetails: () =>
      import("@/components/new-customer/CustomerLoanDetails"),
  },
  data() {
    return {
      view_loan_details: false,
      lead_id: this.decrypt(this.$route.params.id),
    };
  },
  computed: mapGetters({
    getDrawerItems: "drawer/getDrawerItems",
    getManageTab: "drawer/getManageTab",
  }),
  created() {},
  destroyed() {
    this.$store.dispatch("new_customer/setLoanId", "");
  },
  methods: {
    viewDetails() {
      this.view_loan_details = !this.view_loan_details;
    },
    // viewLoanDetails(){
    //   this.view_basic_details = false
    // },
  },
};
</script>
